import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";
import "./components.scss";

function Dropdown({
  className,
  heading,
  options,
  dropIco,
  onClick,
  opened,
  selected,
  history,
  arrow,
}) {
  Dropdown.handleClickOutside = (e) => {
    setTimeout(() => {
      onClick({ opened: false });
    }, 200);
  };

  return (
    <div className={`dropdown ${className}`}>
      <div className="_header flex-x" onClick={() => onClick({ opened: !opened })}>
        <span>{selected || heading}</span>
        {dropIco && <img src={dropIco} alt="" />}
        {arrow && (
          <span
            style={{
              transform: "rotate(90deg)",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {">"}
          </span>
        )}
      </div>
      {opened && (
        <div className="_options">
          {options.map((i, k) => (
            <div
              className="_option"
              onClick={() => {
                console.log("1111111")
                console.log(i)
                onClick({ opened: false, selected: i[1] });
                history && history.push(`/Feed/${i[1]}/` );
              }}
            >
              {i[0]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Dropdown.handleClickOutside,
};

export default onClickOutside(Dropdown, clickOutsideConfig);
