import { useEffect, useState } from "react";
import { VictoryLabel, VictoryPie } from "victory";
import "./feed.scss";
import ClockIco from "../assets/Time icon.svg";
import searchIco from "../assets/Search icon.svg";
import ethIco from "../assets/ethereum.svg";
import { useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import DropDown from "./dropDown";
import RadioModal from "./radioModal";
import backIco from "../assets/Mask Group 1@2x.png";
import { gql, useQuery } from "@apollo/client";

const EXCHANGE_RATES = gql`
  query GetExchangeRates {
    openOracleFrameworkInstances {
      id
      signerThreshold
      payoutAddress
      feesAccrued
      passPrice
      signerList {
        id
        active
        signer
      }
      instanceOracleFeeds{
        name
        id
        description
        decimal
        price
        mode
        instanceOracleFeedSignerValue( orderBy: roundId, orderDirection: desc) {
          id
          roundId
          value
          signer
        }

      }
    }
  }
`;

const alldata = {
  singerLength: "4",
  threshold: "3",
  feeds: "55",
  lastUpdate: "5 sec ago",
  arr: [
    "0x00f0000000f11a5380da5a184f0c563b5995fee2",
    "0x00f0000000f10da5a184f0c563b5995fee2",
    "0x00f0000000f1ddf1a5380da5a184f0c563b5995fee2",
    "0x00f0000000f11a5dfdfdf380da5a184f0c563b5ee2",
  ],
  percentage: 85,
};



const Feed = ({ match }) => {
  const [id, setUser] = useState(match.params);
  const [selectecd, setSelected] = useState("0x00f0000000f11a5380da5a184f0c");
  const [datas, setData] = useState({ ...alldata });
  const [radioInput, setRadioInput] = useState("");
  const [textInput, setTextInput] = useState("");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [oracleList, setOracleList] = useState([]);

  const [feedId, setfeedId] = useState("")

  const { loading, error, data } = useQuery(EXCHANGE_RATES, {
    pollInterval: 500,
  });

  console.log("here")
  console.log(id)

  async function getConjureAssets() {
    console.log(error)
    if (error) return;

    console.log('getting data')
    console.log(data)

    let i;
    let temp_list = []
    let health = {}
    let feedHealth = {}
    let ids = []
    let healthall = 0

    for (i = 0; i < data['openOracleFrameworkInstances'].length; i++) {
      if (data['openOracleFrameworkInstances'][i].id === id.feedId) {

        console.log(12333)

        console.log('list instance')
        console.log(data['openOracleFrameworkInstances'][i]);

        let j;
        health = {}
        let feedLen = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'].length
        healthall = 0

        for (j = 0; j < feedLen; j++) {
          let sigvalues = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['instanceOracleFeedSignerValue']
          let feedid = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['id']

          health[feedid] = {}
          ids.push(feedid)

          // calc values
          let k;
          for (k = 0; k < sigvalues.length; k++) {

            if (health[feedid][sigvalues[k]['roundId']] === NaN || health[feedid][sigvalues[k]['roundId']] === undefined) {
              health[feedid][sigvalues[k]['roundId']] = [sigvalues[k]['signer']]
            }
            else if (health[feedid][sigvalues[k]['roundId']].includes(sigvalues[k]['signer'])) {

            }
            else {
              health[feedid][sigvalues[k]['roundId']].push(sigvalues[k]['signer'])
            }
          }

          let keyer = []
          for (const [key, value] of Object.entries(health[feedid])) {
            keyer.push(key)
          }

          console.log('list keyer')
          console.log(keyer)

          let sum = 0
          keyer.forEach(function (item) {
            sum += health[feedid][item].length
          });

          console.log('list health total')
          console.log(health)

          console.log('stats')
          console.log(sum)
          console.log(parseInt(data['openOracleFrameworkInstances'][i]['signerThreshold']))
          console.log(keyer.length)

          let health1 = sum / parseInt(data['openOracleFrameworkInstances'][i]['signerList'].length) / keyer.length
          console.log('list health')
          console.log(health1)
          feedHealth[feedid] = health1 * 100
          healthall = healthall + health1 * 100
        }

        console.log(health)


        let feeddata = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds']


        let jsoner = {
          label: data['openOracleFrameworkInstances'][i]['id'],
          value: data['openOracleFrameworkInstances'][i]['id'],
          signerThreshold: data['openOracleFrameworkInstances'][i]['signerThreshold'],
          payoutAddress: data['openOracleFrameworkInstances'][i]['payoutAddress'],
          signerList: data['openOracleFrameworkInstances'][i]['signerList'],
          signerLen: data['openOracleFrameworkInstances'][i]['signerList'].length,
          instanceOracleFeeds: feeddata,
          feedHealth: feedHealth,
          feedHealthAll: healthall / feeddata.length
        }
        temp_list.push(jsoner)

      }
    }

    setOracleList(temp_list);
    console.log("ids")
    console.log(temp_list)
  }

  // effect hook for updating data
  useEffect(() => {
    console.log("test load")
    if (loading === false) {
      getConjureAssets();
    }
  }, [loading]);

  return (
    <div className="feed">
      <ReactModal isOpen={modal} onRequestClose={() => setModal(false)}>
        <div><input
          onChange={(e) => setModalData({ ...modalData, days: e.target.value })}
          placeholder={"Days"}
        />
          <input
            onChange={(e) =>
              setModalData({ ...modalData, hours: e.target.value })
            }
            placeholder={"Hours"}
          />
          <div
            onChange={(e) => setModalData({ ...modalData, pay: e.target.value })}
            placeholder={"Total ETH to Pay"}
          >pay</div>
          <div
            className="button subscribe confirm"
            onClick={() => setModal(false)}
          >
            confirm
          </div></div>
      </ReactModal>

      <div className="parent flex-y">
        <div>{id.feedId}</div>

        <div className="block1 flex-x-b">
          <Block heading={"Signer Length"} value={oracleList && oracleList[0] && oracleList[0].signerLen} />
          <Block heading={"Threshold"} value={oracleList && oracleList[0] && oracleList[0].signerThreshold} />
          <Block heading={"Feeds"} value={oracleList && oracleList[0] && oracleList[0].instanceOracleFeeds.length} />
        </div>
        <div className="block4 flex-x-b">
          <Block heading={"Signers"} value=

            {oracleList && oracleList[0] && oracleList[0].signerList && oracleList[0].signerList.map(
              (obj) => <div key={obj.id}>{obj.id}</div>)}
          >
          </Block>

          <div className={"percentage flex-x"}>
            <VictoryPie
              datas={[
                { x: "a", y: oracleList && oracleList[0] && oracleList[0].feedHealthAll.toFixed(0) },
                { x: "b", y: 100 - (oracleList && oracleList[0] && oracleList[0].feedHealthAll.toFixed(0)) },
              ]}
              radius={150}
              style={{ labels: { display: "none" } }}
              innerRadius={140}
              colorScale={["#5FC5FF", "white"]}
            />
            <div className="center flex-y">
              <div>{oracleList && oracleList[0] && oracleList[0].feedHealthAll.toFixed(0) + "%"}</div>
              <div>Health</div>
            </div>
          </div>
        </div>
        
          <div className="block1 flex-x-b">

            <Block heading={"Subscription Price"} value=
              {<div>0.001 ETH per 24h
              </div>}>
            </Block>
            
           <div className="button subscribe">Subscribe</div> <div></div><div></div><div></div><div></div><div></div></div>
            
      </div>
      <FeedOverview
        inputter={oracleList}
      />
    </div>
  );
};
export default Feed;

const Block = ({ heading, value }) => (
  <div className="block flex-y">
    <div>{heading}</div>
    <div>{value}</div>
  </div>
);

const priceOptions = ["All", "USD", "ETH"];

const FeedOverview = ({ inputter }) => {
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();
  const [dropdownPrice, setDropdownPrice] = useState({
    className: "connect-dropdown",
    heading: "Select",
    opened: false,
    selected: "",
    options: priceOptions,
  });

  console.log(inputter)

  const [feedData, setFeedData] = useState([]);

  const [allData, setAllData] = useState([...feedData]);
  const onClick = (obj) => {
    if (obj.selected && obj.selected === "All") obj.selected = "";
    setDropdownPrice((datas) => ({ ...datas, ...obj }));
  };

  useEffect(() => {
    setAllData([
      ...feedData.filter((i, k) => {
        let status =
          i.name.toLowerCase().includes(searchInput.toLowerCase()) &&
          i.name.toLowerCase().includes(dropdownPrice.selected.toLowerCase());

        return status;
      }),
    ]);
  }, [searchInput, dropdownPrice.selected]);

  useEffect(() => {

    if (inputter[0] && inputter[0].instanceOracleFeeds) {
      setFeedData(inputter[0].instanceOracleFeeds)
      setAllData([...inputter[0].instanceOracleFeeds])
    }


  }, [inputter]);

  return (
    <div className="overview">
      <div className="_header flex-x-b">
        <div>FEED Overview</div>
        <div className="flex-x">
          <div className="search flex-x">
            <img src={searchIco} alt="" />
            <input
              placeholder={"Search assets"}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          <DropDown {...dropdownPrice} onClick={(obj) => onClick(obj)} arrow />
        </div>
      </div>
      <div className="_body flex-x-b">
        {allData.map((i, k) => (
          <div
            key={k}
            className="feed-block"
            onClick={() => history.push(`/details/${i.id}/`)}
          >
            <div className="flex-x-b">
              <span>
                <img src={ethIco} alt="" />
                {i.name}
              </span>
              <span></span>
            </div>
            <img src={backIco} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};
