import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import "./App.scss";
import Details from "./components/details";
import Feed from "./components/feed";
import Home from "./components/home";

const PageNotFound = () => {
  return <h1>Page Not Found</h1>;
};

const Routes = () => {
  return (
    <div className="main-body">
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/Home" />} />
        <Route exact path="/Home" component={Home} />
        <Route exact path="/Feed" component={Feed} />
        <Route exact path="/Feed/:feedId" component={Feed} />
        <Route exact path="/Details" component={Details} />
        <Route exact path="/Details/:feedId" component={Details} />
        <Route exact path="/404" component={PageNotFound} />
        <Route exact path="*" render={() => <Redirect to="/404" />} />
      </Switch>
    </div>
  );
};

export default Routes;
