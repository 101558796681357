import {useEffect, useState} from "react";
import "./details.scss";
import supportIco from "../assets/Group 3099.svg";
import subscribeIco from "../assets/Group 3098.svg";
import {formatEther} from "@ethersproject/units";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryGroup,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import {gql, useQuery} from "@apollo/client";
import ReactModal from "react-modal";
import RadioModal from "./radioModal";
import IntegrateModal from "./integrateModal";
import CodeEditor from '@uiw/react-textarea-code-editor';


const EXCHANGE_RATES = gql`
  query GetExchangeRates {
    openOracleFrameworkInstances {
      id
      signerThreshold
      payoutAddress
      feesAccrued
      passPrice
      signerList {
        id
        active
        signer
      }
      instanceOracleFeeds(first:500){
        name
        id
        description
        decimal
        price
        mode
        instanceOracleFeedSignerValue( orderBy: roundId, orderDirection: desc) {
          id
          roundId
          value
          signer
        }

      }
    }
  }
`;


const allData = {
  name: "Name",
  desciption: "Description",
  lastUpdated: "Last Updated",
  lastPrice: "last price",
  feedHealth: "feed health",
  feedDecimals: "feed decimals",
};

const gData = [
  { x: 1, y: 100, y0: 0, details: "extra info" },
  { x: 2, y: 300, y0: 0, details: "extra info" },
  { x: 3, y: 200, y0: 0, details: "extra info" },
  { x: 4, y: 500, y0: 0, details: "extra info" },
  { x: 5, y: 600, y0: 0, details: "extra info" },
  { x: 6, y: 100, y0: 0, details: "extra info" },
  { x: 7, y: 300, y0: 0, details: "extra info" },
];

const Details = ({ match }) => {
  const [ id, setUser] = useState(match.params);
  const [datas, setData] = useState({ ...allData });
  const [graphData, setGraphData] = useState([...gData]);
  const [input, setInput] = useState("");
  const [oracleList, setOracleList] = useState([]);
  const [ymin, setymin] = useState(0)
  const [ymax, setymax] = useState(0)
  const [xticker, setxticker] = useState([])
  const [radioInput, setRadioInput] = useState("");
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [code2, setCode2] = useState(
      `uint256 feedPrice, uint256 feedTimeStamp, uint256 feedDecimals = IOpenOracleFramework(FEEDADDRESS).getFeed(FEEDID);`
  )
  const [code1, setCode1] = useState(
      `
/**
 * @dev Interface of the OpenOracleFramework contract
 */
interface IOpenOracleFramework {
    /**
    * @dev getFeed function lets anyone call the oracle to receive data (maybe pay an optional fee)
    *
    * @param feedID the array of feedId
    */
    function getFeed(uint256 feedID) external view returns (uint256, uint256, uint256);
}`
  );

  const { loading, error, data } = useQuery(EXCHANGE_RATES, {
    pollInterval: 500,
  });

  console.log("here")
  console.log(id)

  async function getConjureAssets() {
    console.log(error)
    if (error) return;

    console.log('getting data')
    console.log(data)

    let i;
    let temp_list = []
    let health = {}
    let feedHealth = {}
    let ids = []
    let healthall = 0
    let gDatas = [];
    let miner = 0
    let maxer = 0
    let tickvals = []

    console.log(id)

    for (i=0; i < data['openOracleFrameworkInstances'].length;i++) {
      if (data['openOracleFrameworkInstances'][i].id === id.feedId.split('-')[0]) {

        console.log(12333)

        console.log('list instance')
        console.log(data['openOracleFrameworkInstances'][i]);

        let j;
        health = {}
        let feedLen = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'].length
        healthall = 0

        for(j=0; j < feedLen; j++) {
          let sigvalues = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['instanceOracleFeedSignerValue']
          let feedid = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['id']

          health[feedid] = {}
          ids.push(feedid)

          // calc values
          let k;
          for(k=0; k < sigvalues.length; k++) {

            if (health[feedid][sigvalues[k]['roundId']] === NaN || health[feedid][sigvalues[k]['roundId']] === undefined) {
              health[feedid][sigvalues[k]['roundId']] = [sigvalues[k]['signer']]
            }
            else if (health[feedid][sigvalues[k]['roundId']].includes(sigvalues[k]['signer'])) {

            }
            else {
              health[feedid][sigvalues[k]['roundId']].push(sigvalues[k]['signer'])
            }
          }

          let keyer = []
          for (const [key, value] of Object.entries(health[feedid])) {
            keyer.push(key)
          }

          console.log('list keyer')
          console.log(keyer)

          let sum = 0
          keyer.forEach(function(item){
            sum += health[feedid][item].length
          });

          console.log('list health total')
          console.log(health)

          console.log('stats')
          console.log(sum)
          console.log( parseInt(data['openOracleFrameworkInstances'][i]['signerThreshold']))
          console.log(keyer.length)

          let health1 = sum / parseInt(data['openOracleFrameworkInstances'][i]['signerList'].length) / keyer.length
          console.log('list health')
          console.log(health1)
          feedHealth[feedid] = health1 * 100
          healthall = healthall + health1 * 100
        }

        console.log(health)


        let feeddata = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds']

        let feeder = []
        let p = 0

        for(p=0; p < feeddata.length; p++) {
          if (feeddata[p].id === id.feedId) {
            console.log(99999)
            feeder = feeddata[p]
          }
        }

        let feedVal = feeder['instanceOracleFeedSignerValue']

        let x = 1
        for(p=0; p < feedVal.length; p++) {
          gDatas.push(
              {x: feedVal.length-1-p, y: parseFloat(formatEther(feedVal[p].value)), y0: 0,details: "details"}
          )
          if (p === 0 || p === feedVal.length-1 ) {
            var utcSeconds = feedVal[p].roundId * 3600;
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(utcSeconds);
            console.log("here")
            console.log(d.toLocaleString())
            tickvals.push(d.toLocaleString())
          } else {
            tickvals.push(p.toString())
          }

          x++
        }

        let temp
        for(p=0; p < gDatas.length; p++) {
          temp = gDatas[p].y
          if (temp >+ maxer) {
            maxer = temp
          }
        }

        miner = maxer
        for(p=0; p < gDatas.length; p++) {
          temp = gDatas[p].y

          if (temp <= miner) {
            miner = temp
          }
        }


        console.log("min max")
        console.log(miner)
        console.log(maxer)


        console.log(gDatas)

        var utcSeconds = feeder['instanceOracleFeedSignerValue'][0].roundId * 3600;
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(utcSeconds);

        console.log(d.toLocaleString())

        let jsoner = {label: data['openOracleFrameworkInstances'][i]['id'],
          value: data['openOracleFrameworkInstances'][i]['id'],
          signerThreshold: data['openOracleFrameworkInstances'][i]['signerThreshold'],
          payoutAddress: data['openOracleFrameworkInstances'][i]['payoutAddress'],
          signerList: data['openOracleFrameworkInstances'][i]['signerList'],
          signerLen: data['openOracleFrameworkInstances'][i]['signerList'].length,
          instanceOracleFeeds: feeddata,
          feedHealth: feedHealth,
          feedHealthAll: healthall / feeddata.length,
          displayval: feeder,
          dater: d.toLocaleString()

        }
        temp_list.push(jsoner)

      }
    }

    let newg = gDatas.reverse()

    console.log(newg)

    setOracleList(temp_list);
    console.log("ids")
    console.log(temp_list)
    setGraphData(newg)
    setymin(miner)
    setymax(maxer)
    setxticker(tickvals.reverse())

    console.log('xer')
    console.log(tickvals)

    setCode2('uint256 feedPrice, uint256 feedTimeStamp, uint256 feedDecimals = IOpenOracleFramework(' + temp_list[0].label  +').getFeed(' +  id.feedId.split('-')[1].substring(2) +');')

  }

  function copyToClipboard (content) {
    console.log(content)
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  function copyToClipboard2 (content) {
    console.log(content)
    const el = document.createElement('textarea');
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  };

  // effect hook for updating data
  useEffect(() => {
    console.log("test load")
    if (loading === false) {
      getConjureAssets();
    }
  }, [loading]);

  const onClick = () => {};
  return (
    <div className="details">
      <ReactModal isOpen={modal} onRequestClose={() => setModal(false)}>
        <p>Copy the Interface Code:</p>
        <CodeEditor
            value={code1}
            language="sol"
            placeholder="Please enter SOL code."
            onChange={(evn) => setCode1(evn.target.value)}
            padding={15}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
        />
        <br></br>
        <Button
            src={subscribeIco}
            heading={"Copy"}
            onClick={copyToClipboard(code1)}
        />
        <br></br>
        <p>Use the following to get the feed data:</p>
        <CodeEditor
            value={code2}
            language="js"
            placeholder="Please enter SOL code."
            onChange={(evn) => setCode2(evn.target.value)}
            padding={15}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
        />
        <br></br>
        <Button
            src={subscribeIco}
            heading={"Copy"}
            onClick={copyToClipboard2(code2)}
        />
      </ReactModal>
      <div className="upper-div flex-x-b">
        <Block
            heading="Name"
            value={oracleList && oracleList[0] && oracleList[0].displayval['name']}
        />
        <Block
            heading="DESCIPTION"
            value={oracleList && oracleList[0] && oracleList[0].displayval['description']}
        />
        <Block
            heading="LAST UPDATED"
            value={oracleList && oracleList[0] && oracleList[0].dater}
        />
        <Block
            heading="LAST PRICE"
            value={oracleList && oracleList[0] && formatEther(oracleList[0].displayval['instanceOracleFeedSignerValue'][0].value)}
        />
        <Block
            heading="FEED HEALTH"
            value={oracleList && oracleList[0] && oracleList[0].feedHealth[id.feedId]}
        />
        <Block
            heading="FEED DECIMALS"
            value={oracleList && oracleList[0] && oracleList[0].displayval['decimal']}
        />
        <div>
          <input
            placeholder={"Enter value"}
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
        <Button
          src={supportIco}
          heading={"Support feed"}
          onClick={() => onClick()}
        />
        <Button
          src={subscribeIco}
          heading={"Subscribe"}
          onClick={() => onClick()}
        />
        <IntegrateModal
            setRadioInput={setRadioInput}
            radioInput={radioInput}
            setModal={setModal}
        />
      </div>

      <div className="charts">
        <div className="_header">Charts</div>
        <div className="chart">
          <div className="_title">
            <div>{oracleList && oracleList[0] && oracleList[0].displayval['name']}</div>
          </div>
          <svg style={{ height: 0 }}>
            <defs>
              <linearGradient id="myGradient" x2="0%" y2="100%">
                <stop offset="10%" stopColor="#5FC5FF57" />
                <stop offset="80%" stopColor="white" />
              </linearGradient>
            </defs>
          </svg>
          {/* <VictoryGroup>

          </VictoryGroup> */}
          <VictoryChart
              domainPadding={{ x: 0, y: 30 }}
              height={250}
              domain={{y: [ymin, ymax]}}>

            <VictoryAxis
                tickValues={xticker}
                tickFormat={(t) => t.length > 3 ? t : ""}
              style={{
                tickLabels: {
                  fontSize: 8,
                  padding: 5,
                  fill: "#7C828A",
                  fontFamily: "karla",
                },
              }}
            />
            <VictoryAxis
              dependentAxis
              style={{
                tickLabels: {
                  fontSize: 8,
                  padding: 5,
                  fill: "#7C828A",
                  fontFamily: "karla",
                },
              }}
            />

            <VictoryArea
              interpolation="natural"
              style={{
                data: { fill: "url(#myGradient)", stroke: "#5FC5FF" },
              }}
              data={graphData}
            />
            <VictoryScatter
              style={{
                data: { fill: "#5FC5FF" },
                labels: { fill: "grey", fontSize: 8, background: "red" },
              }}
              size={({ active }) => (active ? 5 : 3)}
              labels={({ datum }) => `${datum.y} ${datum.details}`}
              labelComponent={
                <VictoryTooltip
                  flyoutWidth={100}
                  flyoutStyle={{
                    stroke: "grey",
                    fill: "white",
                    strokeWidth: 0.1,
                  }}
                  pointerLength={5}
                />
              }
              data={graphData}
            />
          </VictoryChart>
        </div>
      </div>
    </div>
  );
};

export default Details;

const Block = ({ heading, value }) => (
  <div className="block">
    <div>{heading}</div>
    <div>{value}</div>
    <div></div>
  </div>
);

const Button = ({ src, heading }) => (
  <div className="button sub-button">
    <img src={src} alt="" />
    <span>{heading}</span>
  </div>
);
