import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

function IntegrateModal({ radioInput, setRadioInput, setTextInput, setModal }) {
  IntegrateModal.handleClickOutside = (e) => {
    setTimeout(() => {
      setRadioInput("");
    }, 200);
  };

  return (
      <div className="button sub-button" onClick={() => setModal(true)}>
        <span>Integrate Feed</span>
      </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => IntegrateModal.handleClickOutside,
};

export default onClickOutside(IntegrateModal, clickOutsideConfig);
