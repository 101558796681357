import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

function RadioModal({ radioInput, setRadioInput, setTextInput, setModal }) {
  RadioModal.handleClickOutside = (e) => {
    setTimeout(() => {
      setRadioInput("");
    }, 200);
  };

  return (
    <div className="block3 flex-x">
      <div className="radio-input" onBlur={() => console.log("hhh")}>
        <div className="flex-x-b">
          <div onClick={() => setRadioInput("self")} className="flex-x">
            <span
              className={`_input ${radioInput == "self" && "active"}`}
            ></span>{" "}
            <span>Self</span>
          </div>
          <div onClick={() => setRadioInput("gift")} className="flex-x">
            <span
              className={`_input ${radioInput == "gift" && "active"}`}
            ></span>{" "}
            <span>Gift</span>
          </div>
        </div>
        {radioInput && (
          <div className="text-input">
            <input
              onChange={(e) => setTextInput(e.target.value)}
              placeholder={
                radioInput == "self" ? "Enter address" : "Enter amount"
              }
            />
          </div>
        )}
      </div>
      <div className="button subscribe" onClick={() => setModal(true)}>
        subscribe
      </div>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => RadioModal.handleClickOutside,
};

export default onClickOutside(RadioModal, clickOutsideConfig);
