import logo from "./logo.png";
import "./App.scss";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes";
import { useState } from "react";
import DropDown from "./dropDown";
import Discard from "./assets/discord.svg";
import Forum from "./assets/Forum.svg";
import Conjure from "./assets/conjure-logo-Samla.svg";
import Gitbook from "./assets/gitbook-icon.svg";
import Github from "./assets/github.svg";
import Medium from "./assets/medium.svg";
import Telegram from "./assets/telegram_app.svg";
import Twitter from "./assets/twitter.svg";
import { ApolloProvider } from '@apollo/client/react';
import {ApolloClient, InMemoryCache} from "@apollo/client";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./lib/getLibrary";

const mainneturi = "https://api.studio.thegraph.com/query/1187/open-oracle-framework/0.0.7"
const rinkebyuri = "https://api.studio.thegraph.com/query/1187/open-oracle-framework-rinkeby/0.0.3"

const client = new ApolloClient({
  uri: rinkebyuri,
  cache: new InMemoryCache()
});

const FooterIcons = [
  { src: Conjure, link: "https://www.scry.finance" },
  { src: Twitter, link: "https://twitter.com/ScryProtocol" },
  { src: Medium, link: "https://ScryProtocol.medium.com/" },
  { src: Telegram, className: "small", link: "https://t.me/ConjureFinanceDiscussion" },
  { src: Discard, link: "https://discord.gg/3Z2qvm9BDg" },
  { src: Forum, className: "small", link: "https://forum.conjure.finance/" },
  { src: Github, className: "small", link: "https://github.com/ScryProtocol" },
  { src: Gitbook, link: "https://docs.oof.scry.finance/" },
];

const connectOptions = ["Ethereum", "Binance", "Smart chain"];

const App = () => {
  const [dropdownConnect, setDropdownConnect] = useState({
    className: "connect-dropdown",
    heading: "connect",
    opened: false,
    selected: "",
    options: connectOptions,
  });
  const [connect, setConnect] = useState(false);
  const onClick = (obj) => {
    console.log(obj);
    setDropdownConnect((data) => ({ ...data, ...obj }));
  };

  const connectFn = () => {
    setConnect(true);
  };

  return (
      <ApolloProvider client={client}>
        <Web3ReactProvider getLibrary={getLibrary}>
    <div
      className="App"
      onClick={() => dropdownConnect.opened && onClick({ opened: false })}
    >
      <Header
        dropdownConnect={{
          ...dropdownConnect,
          heading: connect ? "connected" : "connect",
        }}
        connect={connect}
        connectFn={connectFn}
        onClick={onClick}
      />
      <Router>
        <Switch>
          <Routes />
        </Switch>
      </Router>
      <Footer />
    </div>
        </Web3ReactProvider>
      </ApolloProvider>
  );
};

export default App;

const Header = ({ dropdownConnect, ...props }) => {
  return (
    
    <div className="header">
     <a href={'/'}> <img src = {logo} width = '64px' className="logo" HREF></img></a>
     <div className="headerScry">Scry Protocol</div>
      <DropDown {...dropdownConnect} {...props} />
    </div>
  );
};

const Footer = () => {
  return (
    <div className="footer">
      <div>
        {FooterIcons.map((i, k) => (
          <div key={k} onClick={() => window.open(i.link)} style={{cursor: "pointer"}}>
            <img src={i.src} alt="" className={i.className} />
          </div>
        ))}
      </div>
      <div>All Rights Reserved</div>
    </div>
  );
};
