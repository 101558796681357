import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";
import "./components/components.scss";

function Dropdown({
  className,
  heading,
  options,
  dropIco,
  onClick,
  opened,
  selected,
  history,
  arrow,
  connect,
  connectFn,
}) {
  Dropdown.handleClickOutside = (e) => {
    setTimeout(() => {
      onClick({ opened: false });
    }, 200);
  };

  return (
    <div className={`dropdown ${className}`}>
      <div
        className="_header flex-x"
        onClick={() => (connect ? onClick({ opened: !opened }) : connectFn())}
      >
        <span>{selected || heading}</span>
        {dropIco && <img src={dropIco} alt="" />}
        {arrow && (
          <span
            style={{
              transform: "rotate(90deg)",
              display: "inline-block",
              marginLeft: "10px",
            }}
          >
            {">"}
          </span>
        )}
      </div>
      {opened && (
        <div className="_options">
          {options.map((i, k) => (
            <div
              className="_option"
              onClick={() => {
                console.log('ok')
                onClick({ opened: false, selected: i });
              }}
            >
              {i}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Dropdown.handleClickOutside,
};

export default onClickOutside(Dropdown, clickOutsideConfig);
