import "./home.scss";
import appIco from "../assets/Scry.png";
import dropdownIco from "../assets/down_button.svg";
import {useEffect, useState} from "react";
import {gql, useQuery} from "@apollo/client";

import { useHistory } from "react-router-dom";
import DropDown from "./dropDown";

const EXCHANGE_RATES = gql`
  query GetExchangeRates {
    openOracleFrameworkInstances {
      id
      signerThreshold
      payoutAddress
      feesAccrued
      passPrice
      signerList {
        id
        active
        signer
      }
      instanceOracleFeeds(first: 500){
        name
        id
        description
        decimal
        price
        mode
        instanceOracleFeedSignerValue( orderBy: roundId, orderDirection: desc) {
          id
          roundId
          value
          signer
        }

      }
    }
  }
`;

const Home = () => {
  const history = useHistory();
  const [oracleList, setOracleList] = useState([]);
  const [selected, setSelected] = useState("");
  const [oracleOptions, setoracleOptions] = useState([
      ['oof.eth',"0x00f0feed50dcdf57b4f1b532e8f5e7f291e0c84b"],
    ["0x118657bda7b8190a62b07c8b4ef03af0db9e375d","0x118657bda7b8190a62b07c8b4ef03af0db9e375d"],
    ["0x13d9f1aa72ca3bb351803550a134221214315a93","0x13d9f1aa72ca3bb351803550a134221214315a93"],
    ["0x72c5fa9b84ba36aa77262dbff3011182f2f25186","0x72c5fa9b84ba36aa77262dbff3011182f2f25186"],
    ["0xc29d1e4abc3917f8999652e7706d6462eda4da0c","0xc29d1e4abc3917f8999652e7706d6462eda4da0c"],
    ["0xec563c994a10fb2fa17cdd1abf446d8ffc407217","0xec563c994a10fb2fa17cdd1abf446d8ffc407217"],
    ["0x609bf1e8e374b6baef1c2a2c6f919a3e77dee30a", "0x609bf1e8e374b6baef1c2a2c6f919a3e77dee30a"]])

  const [dropdownOra, setDropdownOra] = useState({
    className: "oracle-dropdown",
    heading: "Select your oracles",
    dropIco: dropdownIco,
    opened: false,
    selected: "",
    options: oracleOptions,
  });


  const { loading, error, data } = useQuery(EXCHANGE_RATES, {
    pollInterval: 500,
  });

  const onClick = (obj) => {
    console.log(obj);
    setDropdownOra((data) => ({ ...data, ...obj }));
    console.log(obj)

  };

  async function getConjureAssets() {
    console.log(error)
    if (error) return;

    console.log('getting data')
    console.log(data)

    let i;
    let temp_list = []
    let health = {}
    let feedHealth = {}
    let ids = []

    for (i=0; i < data['openOracleFrameworkInstances'].length;i++) {
      console.log('list instance')
      console.log(data['openOracleFrameworkInstances'][i]);
ids.push(data['openOracleFrameworkInstances'][i].id)
      let j;
      health = {}
      let feedLen = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'].length

      for(j=0; j < feedLen; j++) {
        let sigvalues = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['instanceOracleFeedSignerValue']
        let feedid = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds'][j]['id']

        health[feedid] = {}
        ids.push(feedid)

        // calc values
        let k;
        for(k=0; k < sigvalues.length; k++) {

          if (health[feedid][sigvalues[k]['roundId']] === NaN || health[feedid][sigvalues[k]['roundId']] === undefined) {
            health[feedid][sigvalues[k]['roundId']] = [sigvalues[k]['signer']]
          }
          else if (health[feedid][sigvalues[k]['roundId']].includes(sigvalues[k]['signer'])) {

          }
          else {
            health[feedid][sigvalues[k]['roundId']].push(sigvalues[k]['signer'])
          }
        }

        let keyer = []
        for (const [key, value] of Object.entries(health[feedid])) {
          keyer.push(key)
        }

        console.log('list keyer')
        console.log(keyer)

        let sum = 0
        keyer.forEach(function(item){
          sum += health[feedid][item].length
        });

        console.log('list health total')
        console.log(health)

        console.log('stats')
        console.log(sum)
        console.log( parseInt(data['openOracleFrameworkInstances'][i]['signerThreshold']))
        console.log(keyer.length)

        let health1 = sum / parseInt(data['openOracleFrameworkInstances'][i]['signerList'].length) / keyer.length
        console.log('list health')
        console.log(health1)
        feedHealth[feedid] = health1
      }

      console.log(health)


      let feeddata = data['openOracleFrameworkInstances'][i]['instanceOracleFeeds']


      let jsoner = {label: data['openOracleFrameworkInstances'][i]['id'],
        value: data['openOracleFrameworkInstances'][i]['id'],
        signerThreshold: data['openOracleFrameworkInstances'][i]['signerThreshold'],
        payoutAddress: data['openOracleFrameworkInstances'][i]['payoutAddress'],
        signerList: data['openOracleFrameworkInstances'][i]['signerList'],
        instanceOracleFeeds: feeddata,
        feedHealth: feedHealth}
      temp_list.push(jsoner)
    }

    setOracleList(temp_list);

    setoracleOptions(ids)
    console.log("ids")
  }

  // effect hook for updating data
  useEffect(() => {
    console.log("test load")
    if (loading === false) {
      getConjureAssets();
    }
  }, [loading]);

  return (
    <div className="home flex-y">
      <img src={appIco}  alt="" />
      <div className="heading">SCRY EXPLORER</div>
      <DropDown
        {...dropdownOra}
        onClick={(obj) => onClick(obj)}
        history={history}
      />
    </div>
  );
};

export default Home;
